import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";
import { Masquerade } from "../../../services/masquerade.service";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <div *ngIf="!data.bulkEdit">
                    <s25-ng-masquerade-mapping
                        [item]="data.masquerade"
                        [mapping]="true"
                        (saved)="onSaved($event)"
                        (cancelled)="onCancelled()"
                    ></s25-ng-masquerade-mapping>
                </div>
                <div *ngIf="data.bulkEdit">
                    <s25-ng-masquerade-bulk-edit
                        [items]="data.masquerades"
                        (saved)="onSaved($event)"
                        (cancelled)="onCancelled()"
                    ></s25-ng-masquerade-bulk-edit>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditMasqueradeComponent implements OnInit {
    @Input() data: {
        masquerade?: Masquerade;
        masquerades?: any;
        reference?: string;
        bulkEdit?: boolean;
        onSave?: Function;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
