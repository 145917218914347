//@author: devin
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalInterface } from "../modal.interface";

@Component({
    template: `
        <div *ngIf="initModal" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body ngCenter">
                <div class="ngCenter ngS25ModalDialogMsg" [innerHTML]="data.message"></div>
            </div>
            <div class="modal-footer">
                <div class="ngCenter ngS25ModalDialogBtn">
                    <div *ngIf="data.buttonMap.hasYes" class="ngInlineBlock">
                        <button
                            (click)="yes()"
                            class="aw-button aw-button--outline"
                            [class.aw-button--primary]="data.buttonMap.primary == 'Yes'"
                        >
                            Yes
                        </button>
                    </div>
                    <div *ngIf="data.buttonMap.hasContinue" class="ngInlineBlock">
                        <button
                            (click)="continue()"
                            class="aw-button aw-button--outline"
                            [class.aw-button--primary]="data.buttonMap.primary == 'Continue'"
                        >
                            Continue
                        </button>
                    </div>
                    <div *ngIf="data.buttonMap.hasNo" class="ngInlineBlock">
                        <button
                            (click)="no()"
                            class="aw-button aw-button--outline"
                            [class.aw-button--primary]="data.buttonMap.primary == 'No'"
                        >
                            No
                        </button>
                    </div>
                    <div *ngIf="data.buttonMap.hasCancel" class="ngInlineBlock">
                        <button
                            (click)="cancel()"
                            class="aw-button aw-button--outline"
                            [class.aw-button--primary]="data.buttonMap.primary == 'Cancel'"
                        >
                            Cancel
                        </button>
                    </div>
                    <div *ngIf="data.buttonMap.hasOK" class="ngInlineBlock">
                        <button
                            (click)="close()"
                            class="aw-button aw-button--outline"
                            [class.aw-button--primary]="data.buttonMap.primary == 'OK'"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `,
    styles: `
        .ngS25ModalDialogBtn {
            display: flex;
            gap: 0.5em;
            padding-top: 0;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent implements OnInit, ModalInterface {
    @Input() data: any;
    initModal: boolean = false;

    close() {
        this.data.closeModal();
        return this.data.answer;
    }

    continue() {
        this.data.answer = 1;
        return this.close();
    }

    yes() {
        this.data.answer = 1;
        return this.close();
    }

    no() {
        this.data.answer = 0;
        return this.close();
    }

    cancel() {
        this.data.answer = -1;
        return this.close();
    }

    ngOnInit() {
        this.initModal = true;
    }
}
