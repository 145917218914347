import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-embedded-config
                    [token]="data.config"
                    [editMode]="data.mode"
                    (created)="onCreated($event)"
                    (saved)="onSaved()"
                    (cancelled)="onCancelled()"
                ></s25-ng-embedded-config>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditEmbeddedConfigComponent implements OnInit {
    @Input() data: {
        config: string;
        mode?: "create" | "copy" | "edit";
        onSave?: Function;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onCreated(name: string) {
        this.data = { ...this.data, title: `Edit Config: ${name}` }; // Create new reference to trigger change detection
        this.data.onSave?.();
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
