import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { EmailScenario } from "../../bpe/bpe.service";
import { ModalHeaderI } from "../modal.header.component";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-email-scenario
                    [scenario]="data.scenario"
                    [eventReference]="data.reference"
                    (saved)="onSaved()"
                    (cancelled)="onCancelled()"
                ></s25-ng-email-scenario>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEmailScenarioComponent implements OnInit {
    @Input() data: {
        scenario: EmailScenario;
        reference?: string;
        onSave?: VoidFunction;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
