import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";
import { EmailTemplate } from "../../bpe/bpe.service";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <div *ngIf="!data.isToDoTemplate">
                    <s25-ng-email-template-preview
                        [name]="data.name"
                        [code]="data.code"
                        [scenarioName]="data.scenarioName"
                        [reference]="data.reference"
                    ></s25-ng-email-template-preview>
                </div>
                <div *ngIf="data.isToDoTemplate">
                    <s25-ng-todo-email-template-preview
                        [name]="data.name"
                        [code]="data.code"
                        [scenarioName]="data.scenarioName"
                        [reference]="data.reference"
                    ></s25-ng-todo-email-template-preview>
                </div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEmailTemplatePreviewComponent implements OnInit {
    @Input() data: {
        name: EmailTemplate["itemName"];
        code: any;
        scenarioName: string;
        reference: string;
        isToDoTemplate: EmailTemplate["isToDoTemplate"];
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.data.isToDoTemplate ? (this.data.title = "Preview To Do") : "";
        this.isInit = true;
    }
}
