import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";
import { EmailTemplate } from "../../bpe/bpe.service";

@Component({
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-email-template
                    [template]="data.template"
                    [eventReference]="data.reference"
                    (saved)="onSaved($event)"
                    (cancelled)="onCancelled()"
                ></s25-ng-email-template>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditEmailTemplateComponent implements OnInit {
    @Input() data: {
        template: EmailTemplate;
        reference?: string;
        onSave?: Function;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved(data: { id: number }) {
        this.data.onSave?.(data);
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
