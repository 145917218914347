import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";

@Component({
    selector: "s25-ng-modal-edit-document",
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-edit-document
                    [id]="data.id"
                    [mode]="data.mode"
                    [repList]="data.repList"
                    (saved)="onSaved()"
                    (cancelled)="onCancelled()"
                ></s25-ng-edit-document>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditDocumentComponent implements OnInit {
    @Input() data: {
        id: number;
        mode: "create" | "copy" | "edit";
        onSave?: VoidFunction;
        onCancel?: VoidFunction;
    } & ModalHeaderI;

    isInit = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.onSave?.();
        this.data.closeModal();
    }

    onCancelled() {
        this.data.closeModal();
    }
}
