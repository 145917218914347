import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";

@Component({
    selector: "s25-ng-modal-edit-event-form-config",
    template: `
        <div *ngIf="isInit" class="s25-ng">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-ng-event-form-config
                    [id]="data.id"
                    [mode]="data.mode"
                    (saved)="onSaved()"
                    (cancelled)="onCancelled()"
                ></s25-ng-event-form-config>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEditEventFormConfigComponent implements OnInit {
    @Input() data: ModalHeaderI & {
        id: number;
        mode?: "create" | "copy" | "edit";
        onSave?: VoidFunction;
        onCancel?: VoidFunction;
    };
    isInit: boolean = false;

    ngOnInit() {
        this.isInit = true;
    }

    onSaved() {
        this.data.closeModal();
        this.data.onSave?.();
    }

    onCancelled() {
        this.data.closeModal();
        this.data.onCancel?.();
    }
}
