import { Component, Input, OnInit } from "@angular/core";

@Component({
    template: `<div *ngIf="init">
        <s25-modal-header [data]="data"></s25-modal-header>
        <div class="modal-body">
            <div>
                <input
                    type="radio"
                    [(ngModel)]="data.mapping"
                    name="mapping"
                    value="one-by-one"
                    id="1by1"
                    class="c-margin-right--half"
                />
                <label for="1by1">One-by-one</label>
            </div>
            <div>
                <input
                    type="radio"
                    [(ngModel)]="data.mapping"
                    name="mapping"
                    value="date-by-date"
                    id="datebydate"
                    class="c-margin-right--half"
                />
                <label for="datebydate">Date-by-date</label>
            </div>
            <div>
                <input
                    type="radio"
                    [(ngModel)]="data.mapping"
                    name="mapping"
                    value="none"
                    id="nomapping"
                    class="c-margin-right--half"
                />
                <label for="nomapping">No mapping</label>
            </div>
        </div>
        <div class="modal-footer">
            &nbsp;<button href="javascript:void(0);" class="aw-button aw-button--primary" (click)="onDone()">
                Done
            </button>
        </div>
    </div>`,
})
export class ModalEventFormMappingComponent implements OnInit {
    @Input() data: any;

    init: boolean;

    ngOnInit() {
        this.data.mapping ??= "one-by-one";

        this.init = true;
    }

    onDone() {
        this.data.confirmed = true;
        this.data.closeModal();
    }
}
