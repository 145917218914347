import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { ModalHeaderI } from "../modal.header.component";
import { BpeService, EmailTemplate } from "../../bpe/bpe.service";
import { EventService } from "../../../services/event.service";
import { BpeUtil } from "../../bpe/bpe.util";
import { S25Util } from "../../../util/s25-util";
import { ExecutionEnvironment } from "../../../services/execution.enviroment";
import { S25LoadingApi } from "../../s25-loading/loading.api";
import { Bind } from "../../../decorators/bind.decorator";

@Component({
    template: `
        <ng-container *ngIf="isInit">
            <s25-modal-header [data]="data"></s25-modal-header>
            <div class="modal-body">
                <s25-loading-inline [model]="{}"></s25-loading-inline>
                <ng-container [ngSwitch]="status">
                    <div *ngSwitchCase="'noReference'">Please provide an event reference</div>

                    <div *ngSwitchCase="'noTemplate'">
                        No emails are configured. Please select or add a template to this scenario
                    </div>

                    <div *ngSwitchCase="'noEvent'">
                        Failed to load event, please make sure your event reference is correct
                    </div>

                    <div *ngSwitchCase="'noSent'">No emails would have been sent</div>

                    <ng-container *ngSwitchCase="'sent'">
                        <p class="c-margin-bottom--half">
                            The following email{{ data.templates.length === 1 ? "" : "s" }} would have been sent
                        </p>

                        <div
                            *ngFor="let template of data.templates; let index = index"
                            class="rule c-objectDetails c-objectDetails--borderedSection"
                        >
                            <div class="c-sectionHead">
                                <h2>Template: {{ template.name }}</h2>
                            </div>
                            <s25-simple-collapse [defaultCollapsed]="index >= 2">
                                <div class="email">
                                    <s25-ng-email-template-preview
                                        [name]="template.name"
                                        [code]="template.code"
                                        [scenarioName]="data.name"
                                        [reference]="data.reference"
                                    ></s25-ng-email-template-preview>
                                </div>
                            </s25-simple-collapse>
                        </div>
                    </ng-container>

                    <div *ngSwitchCase="'failed'">Something went wrong loading preview</div>
                </ng-container>
            </div>
        </ng-container>
    `,
    styles: `
        .email {
            font-style: italic;
            padding: 1em;
            border-top: 1px solid black;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalEmailScenarioPreviewComponent implements OnInit {
    @Input() data: {
        templates: [
            {
                name: EmailTemplate["itemName"];
                code: EmailTemplate["code"];
            },
        ];
        name: string;
        code: string;
        reference: string;
    } & ModalHeaderI;

    isInit = false;
    status: "noReference" | "noTemplate" | "loading" | "noEvent" | "noSent" | "sent" | "failed" = "loading";

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
    ) {}

    async ngOnInit() {
        if (!this.data.reference) this.status = "noReference";
        else if (!this.data.templates.length) this.status = "noTemplate";
        else await this.checkWillSend();
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    async checkWillSend() {
        this.isLoading(true);
        const id = await EventService.getEventIdByLocator(this.data.reference).catch(this.failed);
        if (!id) return this.failed();
        const event = await BpeService.getEventData(id, true);
        const vars = await BpeUtil.getVariables(event, null, event);
        const code = BpeUtil.interpolateString(vars, this.data.code);
        this.isLoading(false);

        try {
            const willSend = ExecutionEnvironment.run(code, {
                vars,
                eventData: event,
                util: BpeUtil.util,
            });
            this.status = willSend ? "sent" : "noSent";
        } catch (error: any) {
            S25Util.showError(error);
            this.status = "failed";
        }
    }

    isLoading(loading: boolean) {
        if (loading) S25LoadingApi.init(this.elementRef.nativeElement);
        else S25LoadingApi.destroy(this.elementRef.nativeElement);
    }

    @Bind
    failed() {
        this.status = "noEvent";
        this.isLoading(false);
        this.changeDetector.detectChanges();
    }
}
